<template>
	<div class="setting-pay" v-loading = "pageloading">
		<div class="setting-pay-title">支付设置</div>

		<div class="set-item">
			<div class="set-title"><span style="color: #F56C6C;font-size: 16px;padding-right: 3px;vertical-align: -2px;">*</span>支付通道:</div>
			<div class="set-content">
				<div class="radio-box">
					<div class="radio-item" :class="{radioItemHover: payType===0, radioItemCurrent: useType===0}" v-if="mallInfo.IsUseWxPay" @click="changePayType(0)">
						<div class="txt">微信支付</div>
						<div class="use">
							<span v-if="useType===0" style="color: #fff;">当前使用通道</span>
							<span v-else @click.stop="handleUseType(0)">使用该通道</span>
						</div>
					</div>
					<div class="radio-item" :class="{radioItemHover: payType===5, radioItemCurrent: useType===5}" v-if="mallInfo.IsOpen498Pay" @click="changePayType(5)">
						<div class="txt">云收单支付</div>
						<div class="use">
							<span v-if="useType===5" style="color: #fff;">当前使用通道</span>
							<span v-else @click.stop="handleUseType(5)">使用该通道</span>
						</div>
					</div>
					<div class="radio-item" :class="{radioItemHover: payType===8, radioItemCurrent: useType===8}" v-if="mallInfo.IsUseAdaPay" @click="changePayType(8)">
						<div class="txt">Adapay支付</div>
						<div class="use">
							<span v-if="useType===8" style="color: #fff;">当前使用通道</span>
							<span v-else @click.stop="handleUseType(8)">使用该通道</span>
						</div>
					</div>
					<div class="radio-item" :class="{radioItemHover: payType===10, radioItemCurrent: useType===10}" v-if="mallInfo.IsUseSmfPay" @click="changePayType(10)">
						<div class="txt">联厚支付</div>
						<div class="use">
							<span v-if="useType===10" style="color: #fff;">当前使用通道</span>
							<span v-else @click.stop="handleUseType(10)">使用该通道</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="set-item" v-if="payType===0">
			<div class="set-title">
				<span style="color: #F56C6C;font-size: 16px;padding-right: 3px;vertical-align: -2px;">*</span>
				接入微信支付:
			</div>
			<div class="set-content">
				<wxpay ref="wxpay" :configData="WxPayConfig" @reload="getData"></wxpay>
			</div>
		</div>
		<div class="set-item" v-if="payType===5">
			<div class="set-title">
				<span style="color: #F56C6C;font-size: 16px;padding-right: 3px;vertical-align: -2px;">*</span>
				接入云收单支付:
			</div>
			<div class="set-content">
				<pay498 ref="pay498" :configData="FourNineEigthPayConfig" :mallConfigInfo="mallConfigInfo" @reload="getData"></pay498>
			</div>
		</div>
		<div class="set-item" v-if="payType===8">
			<div class="set-title">
				<span style="color: #F56C6C;font-size: 16px;padding-right: 3px;vertical-align: -2px;">*</span>
				接入Adapay支付:
			</div>
			<div class="set-content">
				<adapay ref="adapay" :configData="AdaPayConfig" :mallConfigInfo="mallConfigInfo" @reload="getData"></adapay>
			</div>
		</div>
		<!--2024-10-10 adapayData的判断 原来只有5   -->
		<div class="set-item" v-if="payType===8&&([5,6,9,10].includes(adapayData)||hasConfig)">
			<div class="set-title">
				<span style="color: #F56C6C;font-size: 16px;padding-right: 3px;vertical-align: -2px;">*</span>
				入驻支付渠道:
			</div>
			<div class="set-content">
				<paychannel key="wx" type="wx" ref="paychannel-wx" :config="settle" :state="adapayData" :addressmsg="addressmsg"
				 @changechannel="changechannel" :hasConfig="hasConfig"></paychannel>
				<paychannel key="zfb" type="zfb" style="margin-top:20px" ref="paychannel-zfb" :config="settle" :state="adapayData"
				 :addressmsg="addressmsg" @changechannel="changechannel" :hasConfig="hasConfig"></paychannel>
			</div>
		</div>
		 <!--  -->
		<div v-if="payType===8">
			 <!-- -->
			<div class ="graybox" v-if="AdaPayConfig.Id">
				<div class="title">
					关于Adapay支付常见问题：
				</div>
				<div class = "content">
					<div class = "question">1.为什么客户在支付的时候，微信上会提示“当前商户需补齐相关资料后，才可进行相应的支付交易，请商户联系对接的微信支付服务商”？</div>
					<div class = "reply" style="margin-top:7px">当出现这样的提示时，说明您商户上的微信支付还未完成签约（实名认证）， 
						<el-button type="text" style="margin:0px;padding:0px" @click="signVisible=true">请点击此处继续完成签约</el-button> 
					</div>
				</div>
				<div class = "content">
					<div class = "question">2.扫了第1个问题的二维码以后，页面上显示：暂无可选择的商户号，这种情况应该怎么处理？</div>
					<div class = "reply" style="margin-top:7px">点击页面中的 “查找商户号” ，输入您商城的微信支付子商户号： <span style="color:#f56c6c">{{WxMerCode}}</span> ，并添加</div>
				</div>
				<div class = "content">
					<div class = "question">3.扫码并查找了商户号，微信上提示“商户号商户全称与当前商家名称不一致，无法进行确认”，需要怎么处理？</div>
					<div class = "reply" style="margin-top:7px">遇到这样的提示时，有以下2个原因：</div>
					<div style="margin-left:20px" class = "reply">（1）提示中显示的商家名称并非是营业执照上最新的名称</div>
					<div style="margin-left:20px" class = "reply">（2）扫码后选择的商户号主体全称和注册支付账号使用的营业执照上的全称不一致</div>
					<div class = "reply" style="margin-top:7px">处理方式：扫描第1个问题的二维码后，页面最底部点击「 注册新商家」，使用最新的营业执照（即您在注册支付账号时使用的营业执照），注册一个新的商家。
						<el-link href="https://jusnn6k8al.feishu.cn/docs/doccnDoibLJ5sC5aLpEi4LoF50e?from=from_copylink" target="_blank"
						:underline="false" type="primary">查看注册教程</el-link>
					</div>
				</div>
			</div>
		</div>
		
		<div class="set-item" v-if="payType===10">
			<div class="set-title">
				<span style="color: #F56C6C;font-size: 16px;padding-right: 3px;vertical-align: -2px">*</span>
				接入联厚支付:
			</div>
			<div class="set-content">
				<lianhou ref="lianhou" :configData="SmfPayConfig" :mallConfigInfo="mallConfigInfo" @reload="getData"></lianhou>
			</div>
		</div>

	
	<el-dialog title="提示" :visible.sync="signVisible" width="600px">
		<div>
			<div style="line-height: 24px;padding: 0 10px;">
				微信端支付渠道已经成功开通，扫描下方签约码，完成签约，客户进入商城即可支付订单啦~
				<span style="color: #F56C6C;">（如果没有完成签约，客户将不能支付哦）</span>
			</div>
			<img style="display: block;width: 290px;margin: 10px auto;" src="http://cdn.dkycn.cn/images/melyshop/20210609162404.png"></img>
		</div>
		<div slot="footer" style="text-align: center;">
			<el-button type="primary" @click="signVisible=false">我已完成签约</el-button>
		</div>
	</el-dialog>
	</div>
</template>

<script>
	
	import {
		mapGetters
	} from 'vuex'
	
	import {
		mallpayconfig,
		mallpayconfigsave,
		adapayMerchantmerCode,
		adapayMerchantInfo,
		adaPayConfigmodifyPayChannelOpenStatus
	} from '@/api/TurnTomySelf';
	import {
		mallFreeVersionConfigInfo
	} from '@/api/goods.js'

	import wxpay from './conponents/wxpay.vue';
	import pay498 from './conponents/pay498.vue';
	import adapay from './conponents/adapay.vue';
	import paychannel from './conponents/paychannel.vue';
	import lianhou from './conponents/lianhou.vue';

	export default {
		components: {
			wxpay,
			pay498,
			adapay,
			paychannel,
			lianhou
		},
		data() {
			return {
				payType: -1, //0：微信 云收单支付：5 AdaPay:8
				useType: -1,
				WxPayConfig: {},
				FourNineEigthPayConfig: {},
				mallConfigInfo: {},
				AdaPayConfig: {},
				pageloading:false,
				mallInfo:{},
				WxMerCode:'',
				signVisible:false,
				settle:{},
				addressmsg:{},
      	adapayData:0,
				hasConfig:false,
				SmfPayConfig:{}
			}
		},
		computed: {
			// ...mapGetters([
			// 	'mallInfo'
			// ]),
		},
		created() {
			// console.log(this.mallInfo)
			this.init()
		},
		methods: {
			init(){
				this.getData();
				this.getMallConfig();
				this.getadapayMerchantmerCode()
				this.getAdapayData()
			},
			async changechannel(msg){
				try{
					let res = await adaPayConfigmodifyPayChannelOpenStatus(msg)
					if(res.IsSuccess){
						this.$message.success('操作成功')
						this.init()
					}
				}finally{
					//
				}
			},
			async getAdapayData() {
				try{
					let res = await adapayMerchantInfo();
					if(res.IsSuccess){
						this.adapayData = res.Result.ApplyState;
						// this.adapayData =5
						// console.log(res.Result)
						this.addressmsg = {
							BusinessAddressProvince:res.Result.BusinessAddressProvince,
							BusinessAddressCity:res.Result.BusinessAddressCity,
							BusinessAddressDistrict:res.Result.BusinessAddressDistrict,
							MerchantType:res.Result.MerchantType,
						}
					}
				}finally{
					//
				}
				
			},
			async getadapayMerchantmerCode(){

				let res = await adapayMerchantmerCode()
				this.WxMerCode = res.Result.WxMerCode
			},
			async getData() {
				try{
					this.pageloading = true
					const res = await mallpayconfig();
					if (res.IsSuccess) {
						this.mallInfo = res.Result
						this.WxPayConfig = res.Result.WxPayConfig;
						this.FourNineEigthPayConfig = res.Result.FourNineEigthPayConfig;
						this.AdaPayConfig = res.Result.AdaPayConfig;
						this.settle = res.Result.AdaPayConfig
						this.hasConfig = Boolean(this.AdaPayConfig.APIKEY&&this.AdaPayConfig.AppId&&this.AdaPayConfig.PrivateKey)
						this.SmfPayConfig = res.Result.SmfPayConfig

						if (this.mallInfo.IsOpen498Pay && res.Result.PayBy === 5){
							this.payType = 5;
						}else if (this.mallInfo.IsUseAdaPay && res.Result.PayBy === 8){
							this.payType = 8;
						}else if (this.mallInfo.IsUseWxPay && res.Result.PayBy === 0){
							this.payType = 0;
						}else if (this.mallInfo.IsUseSmfPay && res.Result.PayBy === 10){
							this.payType = 10;
						}
						this.useType = this.payType;
					}
				}finally{
					this.pageloading = false
				}
			},
			async getMallConfig() {
				let res = await mallFreeVersionConfigInfo()
				this.mallConfigInfo = res.Result
			},
			changePayType(index) {
				this.payType = index;
				return;
				
			},
			handleUseType(index){
				var str = '';
				if (index === 0){
					str = '微信支付';
				}
				if (index === 5){
					str = '云收单支付';
				}
				if (index === 8){
					str = 'Adapay支付';
				}
				if (index === 10){
					str = '联厚支付';
				}
				
				this.$confirm('是否确认将支付通道更换为'+str+'？切换成功后，客户支付的订单款项将进入'+str+'账户', {
						confirmButtonText: '确认更换',
						cancelButtonText: '关闭',
					})
					.then(_ => {
						this.useType = index;
						this.saveData(index);
					})
					.catch(_ => {});
			},
			async saveData(index){
				const res = await mallpayconfigsave({
					PayBy: index
				})
			}
		}
	}
</script>
<style lang="less" scoped>
	.graybox{
		background: #F7F8FA;
		border-radius: 2px 2px 2px 2px;
		opacity: 1;
		padding: 20px;
		margin-top:20px;
		.title{
			font-size: 16px;
			font-weight: bold;
			color: #303133;
			line-height: 1.5;
		}
		.content{
			/* border:1px solid black; */
			margin:10px 0px 0px 30px;
			.question{
				font-size: 14px;
				font-weight: 400;
				color: #303133;
				line-height: 2;
			}
			.reply{
				font-size: 14px;
				font-weight: 400;
				color: #606266;
				line-height: 1.5;
				margin-left:15px ;
			}
		}
	}
</style>
<style lang="less" scoped>
	.setting-pay {

		padding: 10px 10px 30px;
		background: #fff;


		.setting-pay-title {
			font-size: 14px;
			padding: 10px;
			border-bottom: 1px solid #eee;
			margin-bottom: 15px;
		}

		.set-item {
			display: flex;
			margin-bottom: 20px;
			margin-top: 30px;

			.set-title {
				flex: 0 0 auto;
				width: 170px;
				text-align: right;
				font-weight: bold;
				font-size: 14px;
				white-space: nowrap;
			}

			.set-content {
				flex: 1 1 auto;
				overflow: hidden;
			}
		}

		.radio-box {
			display: flex;
			align-items: center;
			margin-left: 15px;
			font-size: 12px;

			.radio-item {
				margin-right: 30px;
				position: relative;
				padding: 7px 10px;
				height: 50px;
				width: 140px;
				border: 1px solid #eee;
				border-radius: 4px;
				cursor: pointer;

				.use{
					text-align: right;
					margin-top: 10px;
					
					span{
						color: #409EFF;
					}
				}
			}
			.radioItemHover{
				border-color: #409EFF;
			}
			.radioItemCurrent{
				border-color: #409EFF;
				background-color: #409EFF;
				color: #fff;
			}
		
		}

	}
</style>
